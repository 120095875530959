// 题目类型
export enum QuesType {
    'radio' = '单选题',
    'checkbox' = '多选题',
    'answer' = '问答题',
    // 'practice' = '实操题'
    'acm' = '函数脚本类实操题',
    'project' = '工程项目类实操题',
    'ccm' = '编码测验题',
    'composite' = '组合题',
    'completion' = '填空题'
}

export type QuesTypes = keyof typeof QuesType

/**
 * 题目类型
 *  RADIO('radio', "单选题"), 
 *  CHECKBOX('checkbox', "多选题"), 
 *  ANSWER('answer', "问答题"), 
 *  PRACTICE('practice', "实操题"), 
 *  ACM('acm', "函数脚本类实操题"), 
 *  PROJECT('project', "工程项目类实操题"), 
 *  CCM('ccm', "编码测验题"), 
 *  COMPOSITE('composite', "组合题")
 *  COMPLETION('completion', "填空题")
 */
export enum QuestionType {
    RADIO = 'radio',
    CHECKBOX = 'checkbox',
    ANSWER = 'answer',
    PRACTICE = 'practice',
    ACM = 'acm',
    PROJECT = 'project',
    CCM = 'ccm',
    COMPOSITE = 'composite',
    COMPLETION = 'completion'
}

// 实训状态
export enum trainStatus {
    EDITING = 'EDITING',         // 编辑中
    PUBLISHED = 'PUBLISHED',     // 已发布
    UNPUBLISH = 'UNPUBLISH'      // 下架
}

/**
 *  实训用户状态
 * 
    UN_START(0, "未开始"),
    PROGRESS(1, "进行中"),
    WAIT_SCORE(2, "待判分"),
    IN_SCORE(3, "判分中"),
    FINISHED_SCORE(4, "已判分"),
    UN_JOIN(10, "未参加"),
    RE_PROGRESS(5, "重新作答")
 * 
 */
export enum trainUserStatus {
    UN_START = 'UN_START',
    PROGRESS = 'PROGRESS',
    WAIT_SCORE = 'WAIT_SCORE',
    IN_SCORE = 'IN_SCORE',
    FINISHED_SCORE = 'FINISHED_SCORE',
    UN_JOIN = 'UN_JOIN',
    RE_PROGRESS = 'RE_PROGRESS'
}

/**
 * 编码测验题校验模式 
 *      NONE(0, "无校验"), 
 *      INPUT_OUTPUT(1, "输入输出校验"), 
 *      SUBJECTIVE(2, "主观校验"),
 */
export enum CCMVerifyMode {
    NONE = 'NONE',
    INPUT_OUTPUT = 'INPUT_OUTPUT',
    SUBJECTIVE = 'SUBJECTIVE'
}

/**
 * 填空题分数设置模式 
 *      ALL_MATCH  按题给分
 *      ANY_MATCH  按空给分
 */
export enum CompletionMode {
    ALL_MATCH = 'all_match',
    ANY_MATCH = 'any_match'
}

/**
 *  判分配置
 */
export enum CCMJudgeMode {
    MANUAL = 'MANUAL',        // 手动
    AUTOMATIC = 'AUTOMATIC',  // 自动  使用判分用例
    AI_AGENT = 'AI_AGENT',    // ai模式
}

// 题目难度
export enum DifficultyType {
    'low' = '简单',
    'middle' = '中等',
    'high' = '困难'
}

// 实验室的状态
export enum LabStatus {
    init = 'INIT',        // 初始化
    created = 'CREATED',  // 已发布
    under = 'UNDER'       // 下架
}

/**
 * 实验室类型
 * @description 实验室类型，分为项目实验室0、个人实验室1、机构实验室2(暂时未使用)、教培实验室3、官方实验室10
 */
export enum LabType {
    PROJECT,
    PERSONAL,
    ORGAN,
    EDUCATION,
    OFFICIAL = 10,
}

// 考试相关---------------------------------------

/**
 * 考试学生状态                或考试状态
 * 
 * NOT_START(0, "未开始")     考试前
 * IN_PROGRESS(1, "进行中")   考试中
 * END(2, "已结束")           考试后
 */
export enum ExamUserStatus {
    NOT_START,
    IN_PROGRESS,
    END
}

/**
 * 批量考试状态
 * 
 *  UNCREATED(0, "编辑中"),
 *  CREATED(1, "已发布"),
 *  UNDER(2, "已下架"),
 *  IN_EXAM(3, "考试中"),
 *  IN_SCORE(50, "判分中"),
 *  FINISH_SCORE(5, "已判分"),
 *  PUBLISH_SCORE(70, "已公布成绩"),
 */
export enum BatchExamStatus {
    UNCREATED,
    CREATED,
    UNDER,
    IN_EXAM,
    IN_SCORE = 50,
    FINISH_SCORE = 5,
    PUBLISH_SCORE = 70
}

/**
 * 点对点考试状态
 * 
 *  UNCREATED(0, "编辑中"),
 *  CREATED(1, "已发布"),
 *  UNDER(2, "已下架"),
 *  IN_EXAM(3, "考试中"),
 *  WAIT_SCORE(4, "待判分"),
 *  IN_SCORE(50, "判分中"),
 *  FINISH_SCORE(5, "已判分"),
 */
export enum PointExamStatus {
    UNCREATED,
    CREATED,
    UNDER,
    IN_EXAM,
    WAIT_SCORE,
    IN_SCORE = 50,
    FINISH_SCORE = 5
}

/**
 * 题目状态
 * PREADD('preadd', "预添加模式"), 
 * ADD('add', "添加模式"), 
 * PREVIEW('preview', "预览模式"), 
 * EXAM('exam', "作答模式"), 
 * MARK('mark', "批阅模式"), 
 * VIEW('view', "查看模式"), 
 */
export enum QuestionStatus {
    PREADD = 'preadd',
    ADD = 'add',
    PREVIEW = 'preview',
    EXAM = 'exam',
    MARK = 'mark',
    VIEW = 'view'
}




