import React from 'react'
import './index.less'
import classNames from 'classnames'

type RecordProps = {
    className?: string
    style?: React.CSSProperties
}

const record: { [key: string]: string } = {
    'oscollege.net': '浙ICP备2022034019号-2',
    'cloudlab.top': '浙ICP备2022034019号-4'
}

const Record: React.FC<RecordProps> = (props) => {

    const { className, style } = props

    const hostName = window.location.hostname;
    // 默认第一个
    let recordText = record['oscollege.net']
    for (const key in record) {
        if (hostName.includes(key)) {
            recordText = record[key]
        }
    }

    return (
        <div className={classNames(['record-hostname-gov', className])} style={style}>渡鸦（杭州）互联网有限责任公司&nbsp;&nbsp;&nbsp;&nbsp;<a target='_blank' href='https://beian.miit.gov.cn/'>{recordText}</a></div>
    )
}

export default Record