import React, { useState, useEffect } from 'react'
import "./index.less"
import RichEditor from '../../../../../RichEditor'
import { validateHtml } from '@/utils/htmlParse'


type RemarkItemProps = {
  ifQuest?: boolean   //是否为标题题目
  value?: any
}

const RemarkItem: React.FC<RemarkItemProps> = (props) => {

  const { value, ifQuest } = props



  return (
    <div className='RemarkAnswer-item'>
      {!ifQuest ? <div className='line'>

      </div> : <></>}
      <div className='userImg'>
        {value?.userInfo?.avatar ? <img src={value?.userInfo?.avatar} alt="" style={{ width: '28px', height: '28px', marginRight: '4px', marginTop: '2px', borderRadius: '50%' }} /> : <img style={{ width: '32px', height: '32px', borderRadius: '50%' }} src={require('../../../../../../assets/images/signlogo.png')} alt="" />}
      </div>
      <div className='userText' >
        <div style={{ width: '100%' }}>
          <div className='userText-userName' >
            <span>{value?.userInfo?.displayName
            }</span>
            <span style={{
              color: '#999999',
              marginLeft: '10px'
            }}>{value?.createDateTime}</span>
          </div>
        </div>
        {ifQuest && <div className='userText-bug'>
          {value?.type?.title
            && <span>#{value?.type?.title
            }#</span>}
          {value?.learn && <span style={{ marginLeft: '4px' }}>#影响学习进度#</span>}
        </div>}
        <div className='userText-value'>
          <RichEditor defaultValue={validateHtml(value?.content)} readOnly isShow={true} autoHeight={{ min: 0, max: 1000 }} />

        </div>
      </div>
    </div>
  )
}

export default RemarkItem;
