import { getPublicKey, updateSecret } from '@/api/modules/common';
import { encrypt } from '@/utils/login';
import { Button, Form, Input, message, Modal, Space } from 'antd'
import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import zxcvbn from "zxcvbn";

type UpdatePasswordProps = {
    open: boolean
    type?: 'set' | 'change'  // 设置还是修改
    onCancel?: () => void
    finish?: (data: any) => void // 保存完成回调
}

const UpdatePassword: React.FC<UpdatePasswordProps> = (props) => {

    const { open, onCancel, type = 'set', finish } = props

    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    const save = async () => {
        const data = await form.validateFields().catch(() => { })
        if (!data) return
        const { oldSecret, newSecret } = data
        setLoading(true)
        const publicKey = await getPublicKey('updatePwd').catch(() => {
            setLoading(false)
            message.error('保存失败')
        })
        if (!publicKey) return
        const res = await updateSecret({ oldSecret: oldSecret ? encrypt(publicKey, oldSecret) : undefined, newSecret: encrypt(publicKey, newSecret) }).catch(err => {
            const { message = '保存失败', businessCode } = err
            // businessCode
            // 10401   请输入旧密码
            // 10402    旧密码错误，请重新输入
            // 10403    请输入新密码
            // 10404    密码强度较弱，请输入6-16位英文、数字、符号组合的密码
            if ([10401, 10402].includes(businessCode)) {
                form.setFields([
                    {
                        name: 'oldSecret',
                        errors: [message]
                    }
                ])
            } else if ([10403, 10404].includes(businessCode)) {
                form.setFields([
                    {
                        name: 'newSecret',
                        errors: [message]
                    }
                ])
            }
        }).finally(() => {
            setLoading(false)
        })
        if (res != undefined) {
            message.destroy()
            message.success('密码设置成功！')
            finish?.(res)
            onCancel?.()
        }
    }

    useEffect(() => {
        return () => {
            form.resetFields()
        }
    }, [open])

    return (
        <Modal
            open={open}
            wrapClassName='personal-center-modal'
            footer={
                <Space align="center" size={30} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button style={{ minWidth: '88px' }} onClick={onCancel}>取消</Button>
                    <Button style={{ minWidth: '88px' }} loading={loading} type="primary" onClick={save}>确认</Button>
                </Space>
            }
            closable={false}
            centered={true}
            width={550}
            maskClosable={false}
            destroyOnClose={true}
        >
            <p className='personal-center-modal-title'>{type === 'set' ? '设置' : '修改'}密码</p>
            <div className='personal-center-modal-content'>
                <Form
                    colon={false}
                    autoComplete="off"
                    form={form}
                >
                    {
                        type === 'change' && <Form.Item
                            label='输入旧密码'
                            name="oldSecret"
                            rules={[
                                { required: true, message: '请输入旧密码', validateTrigger: 'onSubmit', whitespace: true },
                            ]}
                            required={false}
                            validateFirst
                        >
                            <Input.Password maxLength={16} placeholder="请输入旧密码" allowClear />
                        </Form.Item>
                    }

                    <Form.Item
                        label={type === 'change' ? '输入新密码' : '输入密码'}
                        name="newSecret"
                        rules={[
                            { required: true, message: '请输入新密码', validateTrigger: 'onSubmit', whitespace: true },
                            { required: true, min: 6, max: 16, message: '请输入6-16位英文、数字、符号组合的新密码', validateTrigger: 'onSubmit', whitespace: true },
                            {
                                validator: (_, value) => {
                                    const score = zxcvbn(value).score
                                    if (score < 2) {
                                        return Promise.reject('密码强度弱，请输入6-16位英文、数字、符号组合的密码')
                                    }
                                    return Promise.resolve()
                                }, validateTrigger: 'onSubmit'
                            }
                        ]}
                        required={false}
                        validateFirst
                    >
                        <Input.Password maxLength={16} placeholder="请输入6-16位英文、数字、符号组合的新密码" allowClear />
                    </Form.Item>

                    <Form.Item
                        label={<span>{type === 'change' ? (<span style={{ display: 'inline-block', width: '1em' }}></span>) : <></>}密码强度</span>}
                        dependencies={['newSecret']}
                    >
                        {
                            () => {
                                const newSecret = form.getFieldValue('newSecret')
                                let title = ''
                                let light = 0
                                if (newSecret) {
                                    const score = zxcvbn(newSecret).score
                                    if (score < 2) {
                                        title = '密码弱'
                                        light = 1
                                    } else if (score < 4) {
                                        title = '密码较弱'
                                        light = 2
                                    } else {
                                        title = '密码较强'
                                        light = 3
                                    }
                                }
                                return <p className='updatepassword-strength'>
                                    <span className={classNames({ 'light': light > 0 })}></span>
                                    <span className={classNames({ 'light': light > 1 })}></span>
                                    <span className={classNames({ 'light': light > 2 })}></span>
                                    {title && <span className='title'>{title}</span>}
                                </p>
                            }
                        }
                    </Form.Item>

                    <Form.Item
                        label={type === 'change' ? '确认新密码' : '确认密码'}
                        name="confirmSecret"
                        rules={[
                            { required: true, message: '请再次输入密码', validateTrigger: 'onBlur', whitespace: true },
                            {
                                validator: (_, value) => {
                                    const newSecret = form.getFieldValue('newSecret')
                                    if (!newSecret || newSecret === value) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject('与输入的密码不一致')
                                    }
                                }, validateTrigger: 'onBlur'
                            }
                        ]}
                        required={false}
                        validateFirst
                    >
                        <Input.Password maxLength={16} placeholder="请再次输入密码" allowClear />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}

export default UpdatePassword