import { lazy } from 'react'
import { Outlet } from 'react-router-dom'
import {
    HomeOutlined,
    FundProjectionScreenOutlined,
    SettingOutlined,
    CodeOutlined,
    ReadOutlined,
    FileTextOutlined,
    CommentOutlined,
    TeamOutlined,
    DeploymentUnitOutlined,
    ToolOutlined,
    QuestionCircleOutlined,
    ProductOutlined,
    ScheduleOutlined,
    SolutionOutlined,
    DownloadOutlined
} from '@ant-design/icons'
import { LabType } from '../utils/globalConst'


const Admin = lazy(() => import('../pages/Admin')) // 后台管理

const Work = lazy(() => import('../pages/Admin/components/Work'))                             // 工作台/未初始化
const CreatWork = lazy(() => import('../pages/Admin/components/Work/components/creatWork'))   // 工作台/初始话完成

const BaseInfo = lazy(() => import('../pages/Admin/components/Base/BaseInfo'))          // 基础配置/实验室基本信息
const EnvManagee = lazy(() => import('../pages/Admin/components/EnvManage'))                  // 基础配置/实验环境管理
const SetEnv = lazy(() => import('../components/ExperEnv/SetEnv'))                     // 基础配置/实验环境管理/配置实验环境管理
const WareHouseConf = lazy(() => import('../pages/Admin/components/GitManage/WareHouseConf')) // 基础配置/Git仓库管理
const Home = lazy(() => import('../pages/Admin/components/Home'))                             // 基础配置/首页站点 

const Quick = lazy(() => import('../pages/Admin/components/MiniLab/Quick'))                         // MiniLab/快速体验
const EditorMessage = lazy(() => import('../pages/Admin/components/MiniLab/EditorMessage'))         // MiniLab/快速体验中/编辑信息
const QuickAnswer = lazy(() => import('../pages/Admin/components/MiniLab/Answer'))         // MiniLab/快速体验中/问答管理
const QuickDetail = lazy(() => import('../pages/Admin/components/MiniLab/Detail'))         // MiniLab/快速体验中/问答管理/详情

const BlogsManage = lazy(() => import('../pages/Admin/components/BlogsManage'))                               // 博客Lab/博客管理
const AnswerBlogsList = lazy(() => import('../pages/Admin/components/BlogsManage/components/AnswerBlogsList'))// 博客Lab/问答管理
const PerfectBlog = lazy(() => import('../pages/Admin/components/BlogsManage/components/PerfectBlog'))        // 博客Lab/编辑博客信息
const AnswerDetail = lazy(() => import('../pages/Admin/components/CourseManage/components/AnswerDetail'))     // 博客Lab/问答详情

const AdminCourseManage = lazy(() => import('../pages/Admin/components/CourseManage'))                            // 教培认证/课程/课程管理
const ProductCourse = lazy(() => import('../pages/Admin/components/ProductCourse'))                               // 教培认证/课程/课程编辑
// const AnswerCouserList = lazy(() => import('../pages/Admin/components/CourseManage/components/AnswerCouserList')) // 教培认证/课程/问答管理
// const CouAnalysis = lazy(() => import('../pages/Admin/components/CourseManage/components/Analysis'))              // 教培认证/课程/分析统计
// const AnalysisDetail = lazy(() => import('../pages/Admin/components/CourseManage/components/AnalysisDetail'))     // 教培认证/课程/分析统计/学习详情
// const KnowledgeGraph = lazy(() => import('../pages/Admin/components/CourseManage/components/KnowledgeGraph'))     // 教培认证/课程/知识图谱
const ExacList = lazy(() => import('../pages/Admin/components/ExacManage/components/ExacList'))                   // 教培认证/考试/考试列表
const CourseList = lazy(() => import('../pages/Admin/components/ExacManage/components/ExacList/Point/CourseList'))// 教培认证/考试/考试列表/课程考试列表
const OtherList = lazy(() => import('../pages/Admin/components/ExacManage/components/ExacList/Point/OtherList'))  // 教培认证/考试/考试列表/第三方考试列表
const CourseListDetail = lazy(() => import('../pages/Admin/components/ExacManage/components/ExacList/Point/CourseListDetail'))  // 教培认证/考试/考课程考试列表/考试详情

const AddExac = lazy(() => import('../pages/Admin/components/ExacManage/components/AddExac'))                     // 教培认证/考试/添加考试
const AnswerList = lazy(() => import('../pages/Admin/components/ExacManage/components/AnswerList'))               // 教培认证/考试/答卷列表
const Analysis = lazy(() => import('../pages/Admin/components/ExacManage/components/Analysis'))                   // 教培认证/考试/分析
const QuesBank = lazy(() => import('../pages/Admin/components/ExacManage/QuesBank'))                              // 教培认证/题库/题库管理
const QuesBankAdd = lazy(() => import('../pages/Admin/components/ExacManage/QuesBank/components/QuesBankAdd'))    // 教培认证/题库/添加试题
const LabelGroupManage = lazy(() => import('../pages/Admin/components/ExacManage/QuesBank/components/LabelGroupManage'))    // 教培认证/题库/标签分组管理
const CertificateManage = lazy(() => import('../pages/Admin/components/EduAndTrain/CertificateManage'))           // 教培认证/证书管理
const CertificateList = lazy(() => import('../pages/Admin/components/EduAndTrain/CertificateList'))               // 教培认证/证书管理/证书列表
const ExamPaper = lazy(() => import('../pages/Admin/components/EduAndTrain/ExamPaper'))                           // 教培认证/试卷管理
const Paper = lazy(() => import('../pages/Admin/components/EduAndTrain/ExamPaper/Paper'))                         // 教培认证/试卷管理/试卷
const TrainingManage = lazy(() => import('../pages/Admin/components/Training/TrainingManage'))                    // 教培认证/实训管理
const TrainingAdd = lazy(() => import('../pages/Admin/components/Training/TrainingAdd'))                          // 教培认证/实训管理/添加实训
const TrainListDetail = lazy(() => import('../pages/Admin/components/Training/TrainListDetail'))                  // 教培认证/实训管理/实训列表

const Issue = lazy(() => import('../pages/Admin/components/GitManage/Issue'))                                     // 互动中心/issue
const AddIssue = lazy(() => import('../pages/Admin/components/GitManage/Issue/AddIssue'))                         // 互动中心/issue/添加issue
const IssueDetail = lazy(() => import('../pages/Admin/components/GitManage/Issue/IssueDetail'))                   // 互动中心/issue/issue详情

const CreateInvita = lazy(() => import('../pages/Admin/components/CreateInvita'))                                 // 用户中心/成员管理
const UserManage = lazy(() => import('../pages/Admin/components/UserManage'))                                     // 用户中心/用户管理
const InfoExtension = lazy(() => import('../pages/Admin/components/UserManage/InfoExtension'))                    // 用户中心/用户管理/信息扩展
const UserMessage = lazy(() => import('../pages/Admin/components/UserManage/UserMessage'))                        // 用户中心/用户管理/用户信息
const DownLoadCenter = lazy(() => import('@/pages/Admin/components/DownloadCenter'))                              // 下载中心

const TaskManage = lazy(() => import('../pages/Admin/components/Task/TaskManage'))                                // 任务中心/任务管理
const TaskData = lazy(() => import('../pages/Admin/components/Task/TaskData'))                                    // 任务中心/任务管理/任务数据

const PersonalHome = lazy(() => import('../pages/Admin/components/PersonalHome'))                                 // 主页
const TeskDetail = lazy(() => import('../pages/Admin/components/PersonalHome/TeskDetail'))

const FeedBack = lazy(() => import('../pages/Admin/components/Help/Feedback'))                                    // 帮助反馈/反馈中心 
const BlogAnswer = lazy(() => import('../pages/Admin/components/Help/BlogAnswer'))                                // 帮助反馈/反馈中心/列表
const AnswerRemark = lazy(() => import('../pages/Admin/components/Help/AnswerRemark'))                            // 帮助反馈/反馈中心/详情


// 路由元信息
interface MetaType {
    title: string                // title
    icon?: React.ReactNode       // icon
    disabled?: boolean           // 路由禁用
    fold?: boolean               // 是否展开
    permissions?: LabType[]      // 权限   不设置表示所有都可以进入
}

const routes = {
    path: '/admin',
    element: <Admin />,
    children: [
        // 主页
        {
            path: 'home',
            meta: {
                title: '主页',
                icon: <HomeOutlined />,
                disabled: false,
                fold: false,
                permissions: [LabType.PERSONAL],
            } as MetaType,
            element: <Outlet />,
            children: [
                {
                    index: true,
                    meta: {
                        title: '主页',
                        disabled: false,
                        permissions: [LabType.PERSONAL],
                    } as MetaType,
                    element: <PersonalHome />
                },
                {
                    path: 'detail',
                    meta: {
                        title: '任务详情',
                        disabled: false,
                        permissions: [LabType.PERSONAL],
                    } as MetaType,
                    element: <TeskDetail />
                },
            ]
        },

        // 工作台
        {
            path: 'work',
            meta: {
                title: '工作台',
                icon: <FundProjectionScreenOutlined />,
                disabled: false,
                fold: false,
                permissions: undefined
            } as MetaType,
            element: <Outlet />,
            children: [
                {
                    index: true,
                    meta: {
                        title: 'work',
                        disabled: false,
                        permissions: undefined
                    } as MetaType,
                    element: <Work />,
                },
                {
                    path: 'creatWork',
                    meta: {
                        title: 'creatwork',
                        disable: false,
                        permissions: undefined
                    } as MetaType,
                    element: <CreatWork />
                },
            ]
        },

        // 基础配置
        {
            path: 'base',
            meta: {
                title: '基础配置',
                icon: <SettingOutlined />,
                disabled: false,
                fold: true,
                permissions: undefined
            } as MetaType,
            element: <Outlet />,
            children: [
                {
                    path: 'labbaseinfo',
                    meta: {
                        title: '实验室基本信息',
                        disabled: false,
                        permissions: undefined
                    } as MetaType,
                    element: <BaseInfo />
                },
                {
                    path: 'labenv',
                    meta: {
                        title: '实验环境管理',
                        disabled: false,
                        permissions: undefined
                    } as MetaType,
                    element: <Outlet/>,
                    children: [
                        {
                            index: true,
                            meta: {
                                title: '实验环境管理',
                                disabled: true
                            } as MetaType,
                            element: <EnvManagee/>,
                        },
                        {
                            path: 'setEnv',
                            meta: {
                                title: '配置实验环境',
                                disabled: true
                            } as MetaType,
                            element: <SetEnv/>
                        },
                    ]
                },
                {
                    path: 'gitmanage',
                    meta: {
                        title: 'Git仓库管理',
                        disabled: false,
                        permissions: [LabType.PROJECT, LabType.OFFICIAL]
                    } as MetaType,
                    element: <WareHouseConf />
                },
                {
                    path: 'homesite',
                    meta: {
                        title: '首页站点',
                        disabled: false,
                        permissions: undefined
                    } as MetaType,
                    element: <Home />
                },
                {
                    path: 'docsite',
                    meta: {
                        title: '文档站点',
                        disabled: true,
                        permissions: undefined
                    } as MetaType,
                    element: <></>
                },
            ]
        },

        // MiniLab
        {
            path: 'minilab',
            meta: {
                title: 'MiniLab',
                icon: <CodeOutlined />,
                disabled: false,
                fold: false,
                permissions: [LabType.PROJECT, LabType.OFFICIAL]
            },
            element: <Outlet />,
            children: [
                {
                    index: true,
                    meta: {
                        title: '快速体验',
                        disabled: false,
                        permissions: [LabType.PROJECT, LabType.OFFICIAL]
                    } as MetaType,
                    element: <Quick />
                },
                {
                    path: 'quickmessage',
                    meta: {
                        title: '编辑快速体验',
                        disabled: true,
                        permissions: [LabType.PROJECT, LabType.OFFICIAL]
                    } as MetaType,
                    element: <EditorMessage />
                },
                {
                    path: 'answer',
                    element: <QuickAnswer />,
                    meta: {
                        title: '问答管理',
                        disabled: true,
                        permissions: [LabType.PROJECT, LabType.OFFICIAL]
                    } as MetaType,
                },
                {
                    path: 'detail',
                    element: <QuickDetail />,
                    meta: {
                        title: '问答详情',
                        disabled: true,
                        permissions: [LabType.PROJECT, LabType.OFFICIAL]
                    } as MetaType,
                },
            ]
        },

        // 博客Lab
        {
            path: 'blog',
            meta: {
                title: '博客Lab',
                icon: <FileTextOutlined />,
                disabled: false,
                fold: false,
                permissions: undefined
            } as MetaType,
            element: <Outlet />,
            children: [
                {
                    index: true,
                    element: <BlogsManage />,
                    meta: {
                        title: '博客管理',
                        disabled: false,
                        permissions: undefined
                    } as MetaType,
                },
                {
                    path: 'answerblogslist',
                    element: <AnswerBlogsList />,
                    meta: {
                        title: '问答管理',
                        disabled: false,
                        permissions: undefined
                    } as MetaType,
                },
                {
                    path: 'blogscourse',
                    element: <PerfectBlog />,
                    meta: {
                        title: '编辑博客信息',
                        disabled: false,
                        permissions: undefined
                    } as MetaType,
                },
                {
                    path: 'answerdetail',
                    element: <AnswerDetail />,
                    meta: {
                        title: '问答详情',
                        disabled: false,
                        permissions: undefined
                    } as MetaType,
                },
            ]
        },

        // 互动中心
        {
            path: 'interactcenter',
            meta: {
                title: '互动中心',
                icon: <CommentOutlined />,
                disabled: false,
                fold: true,
                permissions: undefined
            } as MetaType,
            element: <Outlet />,
            children: [
                {
                    path: 'discussion',
                    meta: {
                        title: '问答讨论',
                        disabled: true,
                        permissions: undefined
                    } as MetaType,
                    element: <></>
                },
                {
                    path: 'issue',
                    meta: {
                        title: 'issue',
                        disabled: false,
                        fold: false,
                        permissions: [LabType.PROJECT, LabType.OFFICIAL]
                    } as MetaType,
                    element: <Outlet />,
                    children: [
                        {
                            index: true,
                            meta: {
                                title: 'issue',
                                disabled: false,
                                permissions: [LabType.PROJECT, LabType.OFFICIAL]
                            } as MetaType,
                            element: <Issue />,
                        },
                        {
                            path: 'addissue',
                            meta: {
                                title: '新建issue',
                                disable: false,
                                permissions: [LabType.PROJECT, LabType.OFFICIAL]
                            } as MetaType,
                            element: <AddIssue />
                        },
                        {
                            path: 'issuedetail',
                            meta: {
                                title: 'issue详情',
                                disable: false,
                                permissions: [LabType.PROJECT, LabType.OFFICIAL]
                            } as MetaType,
                            element: <IssueDetail />
                        }
                    ]
                }
            ]
        },

        // 教培认证
        {
            path: 'examlab',
            meta: {
                title: '教培认证',
                icon: <ReadOutlined />,
                disabled: false,
                fold: true,
                permissions: undefined
            } as MetaType,
            element: <Outlet />,
            children: [
                {
                    path: 'coursemanage',
                    meta: {
                        title: '课程Lab',
                        disabled: false,
                        fold: false,
                        permissions: undefined
                    } as MetaType,
                    element: <Outlet />,
                    children: [
                        {
                            index: true,
                            element: <AdminCourseManage />,
                            meta: {
                                title: '课程Lab',
                                disabled: false,
                                permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                            } as MetaType,
                        },
                        {
                            path: 'productcourse',
                            element: <ProductCourse />,
                            meta: {
                                title: '课程编辑',
                                disabled: false,
                                permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                            } as MetaType,
                        },
                        // {
                        //     path: 'answercouserlist',
                        //     element: <AnswerCouserList />,
                        //     meta: {
                        //         title: '问答管理',
                        //         disabled: false,
                        //         permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                        //     } as MetaType,
                        // },
                        // {
                        //     path: 'answerdetail',
                        //     element: <AnswerDetail />,
                        //     meta: {
                        //         title: '问答详情',
                        //         disabled: false,
                        //         permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                        //     } as MetaType,
                        // },
                        // {
                        //     path: 'analysis',
                        //     element: <CouAnalysis />,
                        //     meta: {
                        //         title: '分析统计',
                        //         disabled: false,
                        //         permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                        //     } as MetaType,
                        // },
                        // {
                        //     path: 'analysisdetail',
                        //     element: <AnalysisDetail />,
                        //     meta: {
                        //         title: '学习详情',
                        //         disabled: false,
                        //         permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                        //     } as MetaType,
                        // },
                        // {
                        //     path: 'knowledgegraph',
                        //     element: <KnowledgeGraph />,
                        //     meta: {
                        //         title: '知识图谱',
                        //         disabled: false,
                        //         permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                        //     } as MetaType,
                        // },
                    ]
                },
                {
                    path: 'exam',
                    meta: {
                        title: '考试Lab',
                        disabled: false,
                        fold: false,
                        permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                    } as MetaType,
                    element: <Outlet />,
                    children: [
                        {
                            index: true,
                            element: <ExacList />,
                            meta: {
                                title: '考试列表',
                                disabled: false,
                                permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                            } as MetaType,
                        },
                        {
                            path: 'addexac',
                            element: <AddExac />,
                            meta: {
                                title: '添加考试',
                                disabled: false,
                                permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                            } as MetaType,
                        },
                        {
                            path: 'answerlist',
                            element: <AnswerList />,
                            meta: {
                                title: '答卷列表',
                                disabled: false,
                                permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                            } as MetaType
                        },
                        {
                            path: 'analysis',
                            element: <Analysis />,
                            meta: {
                                title: '分析',
                                disabled: false,
                                permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                            } as MetaType
                        },
                        {
                            path: 'courselist',
                            element: <CourseList />,
                            meta: {
                                title: '考试列表',
                                disabled: false,
                                permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                            } as MetaType
                        },
                        {
                            path: 'otherlist',
                            element: <OtherList />,
                            meta: {
                                title: '考试列表',
                                disabled: false,
                                permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                            } as MetaType
                        },
                        {
                            path: 'courselistdetail',
                            element: <CourseListDetail />,
                            meta: {
                                title: '考试详情',
                                disabled: false,
                                permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                            } as MetaType
                        }
                    ]
                },
                {
                    path: 'training',
                    meta: {
                        title: '实训管理',
                        disabled: false,
                        fold: false
                    } as MetaType,
                    element: <Outlet />,
                    children: [
                        {
                            index: true,
                            element: <TrainingManage />,
                            meta: {
                                title: '实训管理',
                                disabled: false
                            } as MetaType,
                        },
                        {
                            path: 'addtraining',
                            element: <TrainingAdd />,
                            meta: {
                                title: '新增实训',
                                disabled: false
                            } as MetaType,
                        },
                        {
                            path: 'traininglist',
                            element: <TrainListDetail />,
                            meta: {
                                title: '实训列表',
                                disabled: false
                            } as MetaType,
                        }
                    ]
                },
                {
                    path: 'competition',
                    meta: {
                        title: '竞赛Lab',
                        disabled: true,
                        permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                    } as MetaType,
                    element: <></>
                },
                {
                    path: 'exampaper',
                    meta: {
                        title: '试卷管理',
                        disabled: false,
                        fold: false,
                        permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                    } as MetaType,
                    element: <Outlet />,
                    children: [
                        {
                            index: true,
                            element: <ExamPaper />,
                            meta: {
                                title: '试卷管理',
                                disabled: false,
                                permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                            } as MetaType,
                        },
                        {
                            path: 'paper',
                            element: <Paper />,
                            meta: {
                                title: '试卷',
                                disabled: false,
                                permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                            } as MetaType,
                        },
                    ]
                },
                {
                    path: 'questionbank',
                    meta: {
                        title: '题库管理',
                        disabled: false,
                        fold: false,
                        permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                    } as MetaType,
                    element: <Outlet />,
                    children: [
                        {
                            index: true,
                            element: <QuesBank />,
                            meta: {
                                title: '题库管理',
                                disabled: false,
                                permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                            } as MetaType,
                        },
                        {
                            path: 'labelgroup',
                            element: <LabelGroupManage />,
                            meta: {
                                title: '标签分组管理',
                                disabled: false,
                                permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                            } as MetaType,
                        },
                        {
                            path: 'quesbanadd',
                            element: <QuesBankAdd />,
                            meta: {
                                title: '添加试题',
                                disabled: false,
                                permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                            } as MetaType,
                        },
                    ]
                },
                {
                    path: 'certificate',
                    meta: {
                        title: '证书管理',
                        disabled: false,
                        fold: false,
                        permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                    } as MetaType,
                    element: <Outlet />,
                    children: [
                        {
                            index: true,
                            element: <CertificateManage />,
                            meta: {
                                title: '证书管理',
                                disabled: false,
                                permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                            } as MetaType,
                        },
                        {
                            path: 'certificatelist',
                            element: <CertificateList />,
                            meta: {
                                title: '证书列表',
                                disabled: false,
                                permissions: [LabType.EDUCATION, LabType.OFFICIAL]
                            } as MetaType,
                        },
                    ]
                }
            ]
        },

        // 职场Lab
        {
            path: 'jobmarket',
            meta: {
                title: '职场Lab',
                icon: <SolutionOutlined />,
                disabled: true,
                fold: true,
                permissions: [LabType.PERSONAL]
            } as MetaType,
            element: <></>
        },

        // 任务中心
        {
            path: 'task',
            meta: {
                title: '任务中心',
                icon: <ScheduleOutlined />,
                disabled: false,
                fold: true,
                permissions: undefined
            } as MetaType,
            element: <Outlet />,
            children: [
                {
                    path: 'manage',
                    element: <Outlet />,
                    meta: {
                        title: '任务管理',
                        disabled: false,
                        fold: false,
                        permissions: undefined
                    } as MetaType,
                    children: [
                        {
                            index: true,
                            element: <TaskManage />,
                            meta: {
                                title: '任务管理',
                                disabled: false,
                                permissions: undefined
                            } as MetaType,
                        },
                        {
                            path: 'info',
                            element: <TaskData />,
                            meta: {
                                title: '任务数据',
                                disabled: false,
                                permissions: undefined
                            } as MetaType,
                        }
                    ]
                },
            ]
        },

        // 用户中心
        {
            path: 'user',
            meta: {
                title: '用户中心',
                icon: <TeamOutlined />,
                disabled: false,
                fold: true,
                permissions: undefined
            } as MetaType,
            element: <Outlet />,
            children: [
                {
                    path: 'createinvita',
                    element: <CreateInvita />,
                    meta: {
                        title: '成员管理',
                        disabled: false,
                        permissions: undefined
                    } as MetaType
                },
                {
                    path: 'usermanage',
                    element: <Outlet />,
                    meta: {
                        title: '用户管理',
                        disabled: false,
                        fold: false,
                        permissions: undefined
                    } as MetaType,
                    children: [
                        {
                            index: true,
                            element: <UserManage />,
                            meta: {
                                title: '用户管理',
                                disabled: false,
                                permissions: undefined
                            } as MetaType,
                        },
                        {
                            path: 'infoextension',
                            element: <InfoExtension />,
                            meta: {
                                title: '信息扩展',
                                disabled: false,
                                permissions: undefined
                            } as MetaType
                        },
                        {
                            path: 'usermessage',
                            element: <UserMessage />,
                            meta: {
                                title: '用户信息',
                                disabled: false,
                                permissions: undefined
                            } as MetaType
                        }
                    ]
                },
            ]
        },

        // 下载中心
        {
            path: 'downloadcenter',
            meta: {
                title: '下载中心',
                icon: <DownloadOutlined />,
                disabled: false,
                fold: false,
                permissions: [LabType.PROJECT, LabType.OFFICIAL]
            },
            element: <Outlet />,
            children: [
                {
                    index: true,
                    meta: {
                        title: '下载中心',
                        disabled: false,
                        permissions: [LabType.PROJECT, LabType.OFFICIAL]
                    } as MetaType,
                    element: <DownLoadCenter />
                }
            ]
        },

        // 运营中心
        {
            path: 'operationcenter',
            meta: {
                title: '运营中心',
                icon: <DeploymentUnitOutlined />,
                disabled: true,
                fold: false,
                permissions: undefined
            } as MetaType,
            element: <Outlet />,
            children: []
        },

        // 工具市场
        {
            path: 'toolmarket',
            meta: {
                title: '工具市场',
                icon: <ToolOutlined />,
                disabled: false,
                fold: true,
                permissions: undefined
            } as MetaType,
            element: <Outlet />,
            children: [
                {
                    path: 'coderunner',
                    element: <></>,
                    meta: {
                        title: 'code runner',
                        disabled: true,
                        permissions: undefined
                    } as MetaType,
                },
            ]
        },

        // 帮助反馈
        {
            path: 'help',
            meta: {
                title: '帮助反馈',
                icon: <QuestionCircleOutlined />,
                disabled: false,
                fold: true,
                permissions: undefined
            } as MetaType,
            element: <Outlet />,
            children: [
                {
                    path: 'guide',
                    element: <></>,
                    meta: {
                        title: '新手引导',
                        disabled: true,
                        permissions: undefined
                    } as MetaType,
                },
                {
                    path: 'document',
                    element: <></>,
                    meta: {
                        title: '帮助文档',
                        disabled: true,
                        permissions: undefined
                    } as MetaType,
                },
                {
                    path: 'feedback',
                    element: <Outlet />,
                    meta: {
                        title: '反馈中心',
                        disabled: false,
                        permissions: undefined,
                        fold: false,
                    } as MetaType,
                    children: [
                        {
                            index: true,
                            element: <FeedBack />,
                            meta: {
                                title: '反馈中心',
                                disabled: false,
                                permissions: undefined
                            } as MetaType,
                        },
                        {
                            path: 'list',
                            element: <BlogAnswer />,
                            meta: {
                                title: '问答列表',
                                disabled: false,
                                permissions: undefined,
                            } as MetaType,
                        },
                        {
                            path: 'detail',
                            element: <AnswerRemark />,
                            meta: {
                                title: '问答详情',
                                disabled: false,
                                permissions: undefined,
                            } as MetaType,
                        }
                    ]
                },
            ]
        },

        // 更多模块
        {
            path: 'more',
            meta: {
                title: '更多模块',
                icon: <ProductOutlined />,
                disabled: true,
                permissions: undefined
            } as MetaType,
            element: <></>
        }
    ]
}

export const adminRoutes = routes.children  // 导出的路由
export const prefixPath = routes.path       // 路由地址的前缀
// 设置到后台跳转地址的统一由这里暴露
export const navigateRoutes = {

    work: '/admin/work',                                       // 工作台 
    createWork: '/admin/work/creatWork',                       // 工作台/creatework    

    blog: '/admin/blog',                                       // 博客lab
    blogscourse: '/admin/blog/blogscourse',                    // 博客lab/编辑博客信息
    answerblogslist: '/admin/blog/answerblogslist',            // 博客lab/问答管理
    answerdetail: '/admin/blog/answerdetail',                  // 博客lab/问答详情

    miniLab: '/admin/minilab',                                 // minilab/快速体验
    minilabQuickMessage: '/admin/minilab/quickmessage',        // minilab/快速体验/编辑信息
    quickAnswer: '/admin/minilab/answer',                      // minilab/快速体验/问答管理
    quickDetail: '/admin/minilab/detail',                      // minilab/快速体验/问答管理/问答详情

    homeSite: '/admin/base/homesite',                          // 基础配置/首页站点
    labEnv: '/admin/base/labenv',                              // 基础配置/实验环境管理
    setEnv: '/admin/base/labenv/setEnv',                       // 基础配置/实验环境管理/配置实验环境
    gitManage: '/admin/base/gitmanage',                        // 基础配置/Git仓库管理

    courseManage: '/admin/examlab/coursemanage',               // 教培认证/课程
    // knowledgeGraph: '/admin/examlab/coursemanage/knowledgegraph',// 教培认证/课程/知识图谱
    productCourse: '/admin/examlab/coursemanage/productcourse',// 教培认证/课程/课程编辑信息
    // answerCouserList: '/admin/examlab/coursemanage/answercouserlist',// 教培认证/课程/问答管理
    // answerDetail: '/admin/examlab/coursemanage/answerdetail',  // 教培认证/课程/问答详情
    // couanalysis: '/admin/examlab/coursemanage/analysis',       // 教培认证/课程/分析统计
    // analysisDetail: '/admin/examlab/coursemanage/analysisdetail',// 教培认证/课程/分析统计/学习详情
    examPaper: '/admin/examlab/exampaper',                     // 教培认证/试卷管理
    examPaperDetail: '/admin/examlab/exampaper/paper',         // 教培认证/试卷管理/试卷

    exam: '/admin/examlab/exam',                               // 教培认证/考试
    addexac: '/admin/examlab/exam/addexac',                    // 教培认证/考试/添加考试
    answerlist: '/admin/examlab/exam/answerlist',              // 教培认证/考试/答卷列表
    analysis: '/admin/examlab/exam/analysis',                  // 教培认证/考试/分析
    examCourseList: '/admin/examlab/exam/courselist',          // 教培认证/考试/课程考试列表
    examOtherList: '/admin/examlab/exam/otherlist',            // 教培认证/考试/第三方考试列表
    examCourseListDetail: '/admin/examlab/exam/courselistdetail',// 教培认证/考试/课程考试列表/考试详情

    questionBank: '/admin/examlab/questionbank',               // 教培认证/题库
    quesbanLabelGroup: '/admin/examlab/questionbank/labelgroup',// 教培认证/题库/标签分组管理
    quesbanAdd: '/admin/examlab/questionbank/quesbanadd',      // 教培认证/题库/添加题目

    training: '/admin/examlab/training',                       // 教培认证/实训管理
    addTraining: '/admin/examlab/training/addtraining',        // 教培认证/实训管理/添加实训
    traininglist: '/admin/examlab/training/traininglist',      // 教培认证/实训管理/实训列表

    certificateManage: '/admin/examlab/certificate',           // 教培认证/证书管理
    certificateList: '/admin/examlab/certificate/certificatelist',// 教培认证/证书管理/证书列表

    issue: '/admin/interactcenter/issue',                      // 互动中心/issue
    issueDetail: '/admin/interactcenter/issue/issuedetail',    // 互动中心/issue/issue详情
    addIssue: '/admin/interactcenter/issue/addissue',          // 互动中心/issue/添加issue

    usermanage: '/admin/user/usermanage',                      //用户中心/用户管理
    infoextension: '/admin/user/usermanage/infoextension',     //用户中心/用户管理/信息扩展
    userMessage: '/admin/user/usermanage/usermessage',         //用户中心/用户管理/用户信息
    createinvita: '/admin/user/createinvita',                  //用户中心/成员管理

    downloadCenter: '/admin/downloadcenter',                   //下载中心

    taskManage: '/admin/task/manage',                          //任务中心/任务管理
    taskData: '/admin/task/manage/info',                       //任务中心/任务管理/任务数据
    home: '/admin/home',                                       //主页
    homeDetail: '/admin/home/detail',                          //主页/任务详情

    helpFeedback: '/admin/help/feedback',                      //帮助反馈/反馈中心
    helpFeedbackList: '/admin/help/feedback/list',             //帮助反馈/反馈中心/问答列表
    helpFeedbackDetail: '/admin/help/feedback/detail',         //帮助反馈/反馈中心/问答详情
    
}
export default routes