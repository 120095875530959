import http, { encodeHeaders } from "../index";

// 请求issue列表
export async function getIssueList(params: any) {
  return http.get(`/issues/${params.code}/?current=${params.current}&size=${params.size}`)
}

// 查询issue权限
export async function getLabToken(params: any) {
  return http.get(`/lab/token/find/all/?labCode=${params.labCode}`)
}

// 查询issue权限
export async function issueDetail(params: any) {
  return http.get(`/issues/${params.code}/issue/${params.id}`)
}

// 查询问答列表
export async function getAnswerList(params: any) {
  return http.get(`/answers/${params.code}/${params.id}/?current=${params.current}&size=${params.size}`)
}

// 保存标签
export async function issueLabels(params: any) {
  return http.post(`/issues/${params.code}/issue/${params.id}/labels`,params.data)
}

// 保存标签
export async function compentAnswers(params: any) {
  return http.post(`/answers/${params.code}/${params.id}`, {
    content:params.data
  }, encodeHeaders)
}



// 分页问答数据
export async function getProblemList(params: any) {
  return http.post(`/qa/problem/page`, {
    code: params.code,
    size: params.size,
    current: params.current,
    labCode:params.labCode,
  })
}

// 分页问答数据
export async function deleteProblem(params: any) {
  return http.post(`/qa/problem/delete`, {
    id: params.id,
    code: params.code,
    labCode:params.labCode,
  })
}

// 查询问答详情
export async function getProblemDetaile(params: any) {
  return http.get(`/qa/problem/${params.code}/${params.id}`,{
    labCode:params.labCode
  })
}

// 分页问答评论数据
export async function getProblemAnswer(params: any) {
  return http.post(`/qa/problem/answer/page`, {
id:params.id,
    size: params.size,
    current: params.current,
  })
}

// 评论
export async function commitAnswer(params: any) {
  return http.post(`/qa/problem/answer/commit`, {
    content: params.data,
    id: params.id,
    code: params.code,
    labCode: params.labCode,
  }, encodeHeaders)
}

// 同步issue
export async function syncIssue(params: any) {
  return http.post(`/qa/problem/sync/issue?cooperateUrl=${params.cooperateUrl}`, {
    cooperateUrl:params.cooperateUrl,
    id: params.id,
    code: params.code,
    labCode: params.labCode,
  })
}

// 查询同步状态
export async function issueStatus(params: any) {
  return http.get(`/qa/problem/sync/issue/status/${params.id}`)
}
