import { DomEditor, IButtonMenu, IDomEditor, SlateTransforms } from '@wangeditor/editor'
import { v4 as uuidv4 } from 'uuid'

class InsertFillBlankMenu implements IButtonMenu {
  readonly title = '插入填空项'
  //   readonly iconSvg = `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  // <path d="M11.9761 6.37261H7.52371V2.0238C7.52371 1.73452 7.2892 1.5 6.99992 1.5C6.71064 1.5 6.4761 1.73454 6.4761 2.0238V6.37261H2.0238C1.73452 6.37261 1.5 6.60713 1.5 6.89638C1.5 7.18568 1.73454 7.4202 2.0238 7.4202H6.47612V11.9762C6.47612 12.2652 6.71066 12.5 6.99994 12.5C7.28922 12.5 7.52373 12.2652 7.52373 11.9762V7.4202H11.9761C12.2654 7.4202 12.4999 7.18568 12.4999 6.89638C12.4999 6.60713 12.2654 6.37261 11.9761 6.37261Z" fill="#1677FF"/>
  // </svg>`
  readonly tag = 'button'

  constructor() { }

  getValue(editor: IDomEditor): string | boolean {
    return ''
  }

  isActive(editor: IDomEditor): boolean {
    return false
  }

  isDisabled(editor: IDomEditor): boolean {
    if (editor.selection == null) return true
    if (editor.getElemsByType('fill-blank')?.length >= 20) return true
    // const selectedElems = DomEditor.getSelectedElems(editor)
    // const isMatch = selectedElems.some(elem => {
    //   const type = DomEditor.getNodeType(elem)
    //   if (type === 'pre' || type === 'code') return true 
    // })
    // if (isMatch) return true
    return false
  }

  exec(editor: IDomEditor, value: string | boolean) {
    if (this.isDisabled(editor)) return
    const fillBlankVnode = [
      { text: ' ' },
      {
        type: 'fill-blank',
        key: uuidv4(),
        children: [{ text: '' }]
      },
      { text: ' ' }
    ]
    SlateTransforms.insertNodes(editor, fillBlankVnode)
  }
}

export default InsertFillBlankMenu
