import http, { encodeHeaders } from "../index";

// 绑定github相关

// 查询单个第三方令牌（查询用户绑定的第三方平台）
export async function queryUserToken(type: string) {
    return http.get(`/oauth/token/findOne`, { type })
}

// 获取第三方平台授权
export async function getPlatformAuth(type: string, params: any) {
    return http.get(`/oauth/authorize/${type}`, params)
}

// 获取第三方平台授权失败查询授权信息
export async function getPlatformAuthState(data: any) {
    return http.post(`/oauth/get/state`, data)
}

// 校验个人第三方令牌
export async function verifyToken(data: any) {
    return http.post(`/oauth/verify/token`, data, encodeHeaders)
}

// 保存个人第三方令牌
export async function saveToken(data: any) {
    return http.post(`/oauth/token/save`, data, encodeHeaders)
}

// 查询实验室第三方令牌
export async function getLabToken(data: any) {
    return http.get(`/lab/token/find/one`, data)
}

// 保存实验室第三方令牌
export async function saveLabToken(data: any) {
    return http.post(`/lab/token/save`, data, encodeHeaders)
}

// 实验室 校验地址
export async function verifyLabUrl(data: any) {
    return http.get(`/lab/token/verify/url`, data)
}

// 实验室 校验令牌
export async function verifyLabToken(data: any) {
    return http.get(`/lab/token/verify/token`, data)
}

// 实验室 同步issue
export async function syncIssue(data: any) {
    return http.get(`/issues/${data?.labCode}/sync`)
}

// 实验室 同步 issue 状态
export async function syncIssueStatus(data: any) {
    return http.get(`/issues/${data?.labCode}/sync/status`)
}

// 实验室 同步 issue 状态
export async function querryIssueList(data: any) {
    return http.get(`/issues/${data?.labCode}`, {current: data?.current, size: data?.size})
}

// 实验室 获取issue详情
export async function getIssueDetail(data: any) {
    return http.get(`/issues/${data?.labCode}/issue/${data?.id}`)
}

// 实验室 关闭issue
export async function closeIssue(data: any) {
    return http.delete(`/issues/${data?.labCode}/issue/${data?.id}?completed=${data?.completed ?? true}`)
}

// 实验室 新建issue
export async function createIssue(data: any) {
    return http.post(`/issues/${data?.labCode}/issue`, {
        title: data?.title,
        content: data?.content
    }, encodeHeaders)
}

// 实验室 open issue
export async function openIssue(data: any) {
    return http.put(`/issues/${data?.labCode}/issue/${data?.id}`)
}