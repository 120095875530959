// 获取路径参数值（search）
export const getQueryStringValue = (key: string) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
}

// 获取路径参数值（pathname）
export const getPathnameValue: any = () => {
  const pathname = window.location.pathname;
  const segments = pathname.split("/");
  segments.shift()
  return segments
}

// 判断是否是JSON格式字符串
export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

// 将Base64转换为字符串
export const base64ToText = (base64: any) => {
  try {
    const binary = atob(base64);
    const uint8Array = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      uint8Array[i] = binary.charCodeAt(i);
    }
    const decoder = new TextDecoder();
    return decoder.decode(uint8Array);
  } catch (error) {
    return ''
  }
}

// 文本转base64
export const textToBase64 = (text: string) => {
  const encoder = new TextEncoder();
  const uint8Array: any = encoder.encode(text);
  const binary = String.fromCharCode(...uint8Array);
  return btoa(binary);
}

// 获取文件后缀
export const getFileSuffix = (v: string) => {
  const ary = v.indexOf('.') > -1 ? v.split('.') : []
  return ary.length > 0 ? ary[ary.length - 1] : ''
}

// blob转为Base64
export const toBase64 = async (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result?.toString() ?? '')
    reader.onerror = error => reject(error)
  })
}

// 转换为时分秒 time: 秒数
export const formatTime = (time: any) => {
  const hour = Math.floor(time / 3600)
  const minutes = Math.floor((time % 3600) / 60)
  const second = time % 60
  const hourStr = hour < 10 ? "0" + hour : hour + ""
  const minutesStr = minutes < 10 ? "0" + minutes : minutes + ""
  const secondStr = second < 10 ? "0" + second : second + ""
  return `${hourStr}:${minutesStr}:${secondStr}`
}