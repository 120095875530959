import http, { encodeHeaders } from "../index";
import config from "../../config";

// 创建博客或课程
export async function create(data: any) {
    return http.post(`/course/save`, data)
}

// 实验室模板查询
export async function queryTemplateEnv(data: any) {
    return http.post(`/templateEnv/list`, data)
}

// 环境信息
export async function getTemplateEnv(data: any) {
    return http.get(`/templateEnv/get`, data)
}
// 环境信息 Id and labCode
export async function getTemplateEnvById(data: any) {
    return http.get(`/templateEnv/getById`, data)
}

// 热门博客查询
export async function queryHotBlog(data: any) {
    return http.post(`/course/explore`, data)
}

// 查询默认个人实验室
export async function queryDefaultLab() {
    return http.get(`/lab/default/personal`)
}

// 查询生成的课程地址是否重复
export async function queryCourseCode(params: any) {
    return http.get(`/course/verify`, params)
}


// 获取博客列表
export async function getBlogPage(data: any) {
    return http.post(`/course/blogPage`, data)
}

// 获取投放的实验室
export async function layLabs(data: any) {
    return http.get(`/course/lay/labs?code=${data}`)
}

// 博客保存
export async function saveBlog(data: any) {
    return http.post(`/course/save`, data)
}

//获取创作的博客
export async function blogCourse(params: any) {
    return http.post(`course/user/blogPage`, params);
}

// 获取博客index.md内容
export async function getFSSResource(params: any) {
    return http.get(`${config.openURL}/fss/resource/${params?.repo}/${params?.path}`, {}, {responseType: 'text'})
}

// 获取博客index.md内容
export async function getFSSGitlab(params: any) {
    return http.get(`${config.openURL}/fss/gitlab/${params?.repo}?file=${params?.path}`)
}


// 问答
// 发布问答
export async function getQuestionAnswerType() {
    return http.get(`/config/enums/QuestionAnswerType`)
}

// 发布问答
export async function publishQuesAndAns(data: any) {
    return http.post(`/qa/problem/commit`, data, encodeHeaders)
}
