import { h } from 'snabbdom'
import { IDomEditor, IModuleConf, DomEditor } from '@wangeditor/editor'
import { v4 as uuidv4 } from 'uuid'
import withFillBlank from './plugin'
import InsertFillBlankMenu from './menu'

// 复制粘贴：出现重复key，导致填空序号不变，因此需要在每次渲染找到对应key的位置
let fillBlankIds: string[] = []

const renderFillBlank = (elem: any, children: any, editor: any) => {
  const elems = editor?.getElemsByType('fill-blank')
  const index: number = elems?.findIndex((child: any) => child['key'] === elem['key'] && !fillBlankIds.includes(child['id'])) || 0
  if (index === elems.length - 1) {
    fillBlankIds = []
  } else {
    fillBlankIds.push(elems[index]['id'])
  }

  return h('input', {
    attrs: {
      class: 'w-e-fill-blank',
      'data-w-e-type': 'fill-blank',
      'data-w-e-is-void': 'true',
      'data-w-e-is-inline': 'true',
      readonly: true,
      placeholder: index + 1
    }
  })
}

const fillBlankToHtml = (elem: any, childrenHtml: any, editor: IDomEditor | undefined) => {
  return `<input class="w-e-fill-blank" data-w-e-type="fill-blank" data-w-e-is-void="true" data-w-e-is-inline="true" readonly="true">`
}

const parseFillBlankHtml = (elem: any, children: any, editor: any) => {
  return {
    type: 'fill-blank',
    key: uuidv4(),
    children: [{ text: '' }]
  }
}

// 处理code标签里的fill-blank
const parseCodeHtml = (elem: any, children: any[], editor: IDomEditor) => {
  let htmlStr = elem?.innerHTML || ''
  const fillBlankStr = '<input class="w-e-fill-blank" data-w-e-type="fill-blank" data-w-e-is-void="true" data-w-e-is-inline="true" readonly="true">'
  const fillBlanks = children?.filter((child: any) => child?.type === 'fill-blank') || []
  let _children: any[] = []
  if (fillBlanks?.length) {
    children?.forEach((child: any, index: number) => {
      _children.push(child)
      if (child?.type === 'fill-blank') {
        if (children[index + 1]?.type === 'fill-blank') {
          // 获取第二个fillBlankStr的位置
          const index1 = htmlStr?.indexOf(fillBlankStr, 1)
          let str = htmlStr?.slice(0, index1)
          htmlStr = htmlStr?.replace(str, '')
          str = str.replace(fillBlankStr, '')
          // 处理连续两个换行的fill-blank，保存后不换行的情况
          _children.push({ text: str })
        } else {
          htmlStr = htmlStr?.replace(fillBlankStr, '')
        }
      } else {
        htmlStr = htmlStr?.replace(child?.text, '')
      }
    })
  }
  return {
    type: 'code',
    language: '',
    children: fillBlanks?.length ? _children : [{ text: elem?.textContent || '' }]
  }
}

const fillBlankModule: Partial<IModuleConf> = {
  editorPlugin: withFillBlank,
  renderElems: [
    {
      type: 'fill-blank',
      renderElem: renderFillBlank
    }
  ],
  elemsToHtml: [
    {
      type: 'fill-blank',
      elemToHtml: fillBlankToHtml
    }
  ],
  parseElemsHtml: [
    {
      selector: "input[data-w-e-type='fill-blank']",
      parseElemHtml: parseFillBlankHtml
    },
    {
      selector: 'pre:not([data-w-e-type])>code', // 处理code标签里的fill-blank
      parseElemHtml: parseCodeHtml
    }
  ],
  menus: [
    {
      key: 'fill-blank',
      factory() {
        return new InsertFillBlankMenu()
      }
    }
  ]
}

export default fillBlankModule
