import {parseFragment, serialize} from 'parse5'
import DOMPurify from 'dompurify'

// 校验字符串内容是否符合富文本格式  不符合自动补充
export function validateHtml(htmlString: string): string {
    try {
        const clean = DOMPurify.sanitize(htmlString, { FORBID_TAGS: ['style'] })
        const fragment = parseFragment(clean)
        traverse(fragment)
        return serialize(fragment)
    } catch (error) {
        return htmlString
    }
}

// 遍历每个直接子节点
const span = ['strong', 'u', 'em', 'span', 'img', 'br']
function traverse(node: any) {
    const length = node.childNodes.length
    for (let i = 0; i < length; i++) {
        const element = node.childNodes[i]
        if (span.includes(element.tagName) || element.nodeName === '#text') {
            const pNode = {
                nodeName: 'p',
                tagName: 'p',
                attrs: [],
                namespaceURI: 'http://www.w3.org/1999/xhtml',
                childNodes: [element],
                parentNode: element.parentNode
            }
            element.parentNode = pNode
            node.childNodes[i] = pNode
        }
    }
}

// console.log(validateHtml(`<p>sdfdsf</p>`))
// console.log(validateHtml(`<span>sdfdsf</span>121212<p>sdfdsf</p>`))
// console.log(validateHtml(`121323`))
