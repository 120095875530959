import React, { CSSProperties, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import './index.less'
import { IDomEditor, IEditorConfig, IToolbarConfig, Boot } from '@wangeditor/editor'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import '@wangeditor/editor/dist/css/style.css'
import markdownModule from '@wangeditor/plugin-md'
import { imageUpload } from '../../api/modules/product'
import Cookies from 'js-cookie'
import { changeIsLogin } from '../../store/modules/loginSlice'
import { useDispatch } from 'react-redux';
import FillBlank from './FillBlank'

Boot.registerModule(FillBlank)
Boot.registerModule(markdownModule)

export interface RichEditorProps {
    defaultValue?: string                 // 编辑器默认值  默认空
    readOnly?: boolean                    // 是否只读   默认false
    editorHeight?: number | string        // 编辑器高度 默认高度 300px
    placeholder?: string                  // 默认提示内容
    isShow?: boolean                      // 是否需要边框
    ifAnser?: any
    autoHeight?: {                        // 最小高度和最大高度，仅在只读模式下生效，当该设置生效后，height失效
        min: number,
        max: number
    }
    onChange?: (value: string, editor?: IDomEditor) => void    // 当输入内容发生变化时调用（内容可能会仅包含空白字符，如需获取实际内容可通过暴露的方法getHtml进行获取）
    getHtml?: () => void                  // 获取是否有值     建议使用onChange
    customToolbarKeys?: any               // 自定义菜单项 "fill-blank"--插入填空项
    noPadding?: boolean                   // 是否不需要内边距的padding 默认false
    mode?: 'default' | 'simple'
    uploadImageLimit?: (file: File) => boolean // 上传图片前的限制
    [key: string]: any
}

let RichEditor: React.FC<RichEditorProps> = (props, ref) => {

    const dispatch = useDispatch()

    const { defaultValue = '', noPadding = false, readOnly = false, editorHeight = 300, placeholder = '请输入内容...', isShow, getHtml, autoHeight, ifAnser, onChange, customToolbarKeys, insertKeys, mode = 'default', uploadImageLimit } = props

    // editor 实例
    const [editor, setEditor] = useState<IDomEditor | null>(null)
    // 编辑器内容
    const [value, setValue] = useState(defaultValue)
    // 检验状态控制 true  检验失败  false 没有任何变化
    const [status, setStatus] = useState(false)  

    // 工具栏配置
    const [toolbarConfig, setToolbarConfig] = useState<Partial<IToolbarConfig>>({
        toolbarKeys: customToolbarKeys ?? [
            "undo",
            "redo",
            "fullScreen",
            "|",
            "headerSelect",
            "bold",
            "underline",
            "italic",
            "color",
            "bgColor",
            // "|",
            // "fontSize",
            // "fontFamily",
            // "lineHeight",
            "|",
            "bulletedList",
            "numberedList",
            // "|",
            {
                key: "group-justify",
                title: "对齐",
                iconSvg: `<svg viewBox="0 0 1024 1024"><path d="M204.8 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path><path d="M505.6 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path><path d="M806.4 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path></svg>`,
                menuKeys: [
                    "justifyLeft",
                    "justifyRight",
                    "justifyCenter",
                    "justifyJustify"
                ]
            },
            {
                "key": "group-indent",
                "title": "缩进",
                "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z\"></path></svg>",
                "menuKeys": [
                    "indent",
                    "delIndent"
                ]
            },
            "insertLink",
            {
                "key": "group-image",
                "title": "图片",
                "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M959.877 128l0.123 0.123v767.775l-0.123 0.122H64.102l-0.122-0.122V128.123l0.122-0.123h895.775zM960 64H64C28.795 64 0 92.795 0 128v768c0 35.205 28.795 64 64 64h896c35.205 0 64-28.795 64-64V128c0-35.205-28.795-64-64-64zM832 288.01c0 53.023-42.988 96.01-96.01 96.01s-96.01-42.987-96.01-96.01S682.967 192 735.99 192 832 234.988 832 288.01zM896 832H128V704l224.01-384 256 320h64l224.01-192z\"></path></svg>",
                "menuKeys": [
                    "insertImage",
                    "uploadImage"
                ]
            },
            "insertTable",
            "codeBlock",
            "divider",
            "|",
        ]
    })

    // 编辑器配置
    const editorConfig: Partial<IEditorConfig> = {
        placeholder: placeholder,
        autoFocus: false,
        MENU_CONF: {
            uploadImage: {
                async customUpload(file: File, insertFn: any) {
                    if (uploadImageLimit && uploadImageLimit(file)) return
                    const formData = new FormData()
                    formData.append('file', file)
                    const res = await imageUpload({ file: formData })
                    if (res?.url) {
                        insertFn(res?.url, res?.url, res?.url)
                    }
                }
            }
        }
    }

    // 退出销毁实例
    useEffect(() => {
        return () => {
            if (editor == null) return
            editor?.destroy()
            setEditor(null)
        }
    }, [editor])

    // 当前编辑器形态
    useEffect(() => {
        if (readOnly) {
            editor?.disable()
        } else {
            editor?.enable()
        }
    }, [readOnly, editor])

    // 替换字符串中p标签空白字符等
    const replace_p_nbsp_br = useCallback((str: string = '') => {
        return str.replace(/<\/?p>/g, '')
            .replace(/&nbsp;/gi, '')
            .replace(/<br\/?>/g, '')
    }, [])

    // 暴露方法
    useImperativeHandle(ref, () => ({
        // 获取内容
        getHtml: () => {
            if (editor?.isEmpty()) {
                return ''
            } else {
                const text = editor?.getHtml() ?? ''
                const res = replace_p_nbsp_br(text)
                if (/\S+/.test(res)) {
                    return text
                } else {
                    return ''
                }
            }
        },
        // 设置value  设置成功返回true 失败返回false
        setValue: (val: string = ''): boolean => {
            if (editor) {
                editor?.setHtml(val)
                setValue(val)
                return true
            } else {
                return false
            }
        },
        // 校验  只能校验有没有值  校验失败边框会变红(仅在有边框时会生效)
        validateField: (): {status: boolean, value: string} => {
            const res = {
                status: false,
                value
            }
            if (value) {
                res.status = true
                setStatus(false)
            } else {
                setStatus(true)
            }
            return res
        }
    }))

    // 当校验不通过时监听校验通过
    useEffect(() => {
        if (status) {
            if (value) {
                setStatus(false)
            } else {
                setStatus(true)
            }
        }
    }, [status, value])

    useEffect(() => {
        setValue(defaultValue)
        editor?.setHtml(defaultValue)
    }, [defaultValue])


    const style: CSSProperties = {}
    if (readOnly && autoHeight) {
        style.minHeight = autoHeight.min + 'px'
        style.maxHeight = autoHeight.max + 'px'
        style.overflowY = 'auto'
    } else {
        style.height = typeof editorHeight === 'number' ? editorHeight + 'px' : editorHeight as string
    }

    return <>
        <div data-nopadding={noPadding} style={{ border: isShow ? '' : (status ? '1px solid #ff4d4f' : '1px solid #E5E5E5'), zIndex: 99, }} className='richeditor' onClick={() => {
            const token = Cookies.get('token')
            if (!token && ifAnser) {
                dispatch(changeIsLogin({ isLogin: true }))
                editor?.blur()
            }
        }}>
            {
                !readOnly && (
                    <Toolbar
                        editor={editor}
                        defaultConfig={toolbarConfig}
                        mode="default"
                        style={{ borderBottom: '1px solid #E5E5E5' }}
                    />
                )
            }
            <Editor
                defaultConfig={editorConfig}
                value={value}
                onCreated={setEditor}
                onChange={(editor: IDomEditor) => {
                    if (!editor?.isEmpty()) {
                        const html = editor?.getHtml() ?? ''
                        const res = replace_p_nbsp_br(html)
                        if (/\S+/.test(res)) {
                            onChange?.(html, editor)
                            setValue(html)
                        } else {
                            onChange?.('', editor)
                            setValue('')
                        }
                    } else {
                        onChange?.('', editor)
                        setValue('')
                    }
                    getHtml?.()
                }}
                mode={mode}
                style={style}
            />
        </div>
    </>
}

RichEditor = forwardRef(RichEditor as any)

export default RichEditor