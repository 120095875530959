import React, { useEffect, useState } from 'react'
import './index.less'
import All from './All'
import { useSearchParams } from 'react-router-dom'
import LoginBg from '../../components/loginBg'
import Cookies from 'js-cookie'
import { getDetail } from '../../api/modules/common'
import { handleRedirect } from '../../utils/url'
import Record from '@/components/Record'



const Login: React.FC = () => {

    const [search] = useSearchParams()

    const repo = search.get('repo') || ''

    const sign = search.get('sign') || ''
    // const modal = search.get('modal') || ''

    useEffect(() => {
        // modal 可能没有
        if (Cookies.get('token') && !(repo && sign)) {
            isLogin()
        }
    }, [])

    const isLogin = async () => {
        const res = await getDetail().catch(() => { })
        if (!res) return
        const { isLogin } = res
        if (!isLogin) return
        const url = await handleRedirect('')
        window.open(url, '_self')
    }


    return (
        <div className='login'>
            <div className='login-left'>
                <LoginBg></LoginBg>
            </div>
            <div className='login-right'>
                <All />
                <Record />
            </div>
        </div>
    )
}

export default Login