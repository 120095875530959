import React, { useRef, useState } from 'react'
import './index.less'
import { Button, Form, Input, message, Typography } from 'antd'
import { verifyCode, verifyPhone } from '../../../utils/verify'
import { useCodeTimer } from '../../../hooks/CodeTimer'
import PriPolicy from '../../../components/PriPolicy'
import OtherLogin from './OtherLogin'
import { getDetail, getLogin, getSms } from '../../../api/modules/common'
import { useNavigate, useSearchParams } from 'react-router-dom'
import config from '../../../config'
import { getKaiYuanLoginSlient, getTopHost, getYunYan, getYunYanLoginSlient, handleHost } from '../../../utils/url'
import Cookies from 'js-cookie'
import { useLogin } from '../../../hooks/Login'

type LoginFormProps = {

}

const LoginForm: React.FC<LoginFormProps> = (props) => {

  const { } = props
  const [search] = useSearchParams()
  const navigate = useNavigate()

  // // 回调地址
  // const _redirect = search.get('redirect') ? decodeURIComponent(search.get('redirect')!) :  config.kaiyuanURL!

  const [form] = Form.useForm()
  const [time, keepTime, handleTime] = useCodeTimer()
  const { login: setLogin } = useLogin()

  const [loading, setLoading] = useState(false)

  // 登录
  const login = async () => {
    const values = await form.validateFields().catch(() => { })
    if (!values) return
    if (loading) return
    setLoading(true)
    const res = await getLogin({
      phone: values.phone.trim(),
      smsCode: values.smsCode.trim()
    }).catch((error) => {
      const { code } = JSON.parse(error.message)
      switch (code) {
        case 1:
          form.setFields([{
            name: 'phone',
            errors: ['账号未注册，请先注册！']
          }])
          break
        case 2:
          form.setFields([{
            name: 'smsCode',
            errors: ['请输入正确手机号或验证码']
          }])
          break
        default:
          break
      }
    }).finally(() => {
      setLoading(false)
    })
    if (!res) return
    await handleToken(res.token)
    window.open(getYunYanLoginSlient(`?redirect=${encodeURIComponent('')}&token=${res.token}`), '_self')
    // const redirect = new URL(_redirect)
    // // 重定向的域名  当前的域名   只截取后面的一级域名
    // const redirectHost = handleHost(redirect.host)
    // const localtHost = handleHost(window.location.host)
    // // 域名相同都是云燕，直接跳转重定向地址
    // if (redirectHost === localtHost) {
    //   window.open(getYunYanLoginSlient(`?redirect=${encodeURIComponent(_redirect)}&token=${res.token}`), '_self')
    // } else { // 域名不相同说明是其他域跳转过来的,跳转其他域的登录静默页，其他静默页为开源学堂
    //   window.open(getKaiYuanLoginSlient(`?redirect=${encodeURIComponent(_redirect)}&token=${res.token}`), '_self')
    // }
  }

  // 在当前域设置cookie
  const handleToken = async (_token: string) => {
    Cookies.set('token', _token, { domain: getTopHost() || undefined, samesite: 'None', secure: true })
    const res = await getDetail().catch(() => { })
    if (!res) return
    const { isLogin, token, sign, partner, userId } = res
    setLogin({ token, sign, partner, userId }, res)
  }

  const codeLoading = useRef(false)
  // 登录获取验证码
  const getCode = async () => {
    const values = await form.validateFields(['phone']).catch(() => { })
    if (!values) return
    if (codeLoading.current) return
    codeLoading.current = true
    const res = await getSms({ phone: values.phone.trim(), type: 0 }).catch(() => { }).finally(() => {
      codeLoading.current = false
    })
    if (!res) return
    message.success('验证码已发送')
    keepTime(handleTime(res.minutesLeft))
  }

  // 密码登录
  const passwordLogin = () => {
    window.open(getYunYan(`/loginaccount?from=${encodeURIComponent(window.location.href)}&redirect=`), '_self')
  }

  return <>
    <Form
      name="login-all-form"
      className='login-all-form'
      validateTrigger='onBlur'
      autoComplete="off"
      form={form}
    >
      <Form.Item
        label={false}
        name="phone"
        rules={[
          { required: true, message: '请输入手机号码', validateTrigger: 'onSubmit', whitespace: true },
          {
            validator: (_, value) => {
              if (!value) return Promise.resolve()
              if (verifyPhone(value)) {
                return Promise.resolve()
              }
              return Promise.reject('请填写有效手机号码')
            }, validateTrigger: 'onBlur'
          }
        ]}
        validateFirst
      >
        <Input maxLength={11} prefix={<div className='login-all-form-prefix' >
          <img src={require('../../../assets/images/phoneIcon.png')} alt="" />
        </div>} placeholder="请输入手机号码" allowClear />
      </Form.Item>

      <Form.Item
        label={false}
        name="smsCode"
        rules={[
          { required: true, message: '请输入验证码', validateTrigger: 'onSubmit', whitespace: true },
          {
            validator: (_, value) => {
              if (!value) return Promise.resolve()
              if (verifyCode(value)) {
                return Promise.resolve()
              }
              return Promise.reject('请输入有效验证码')
            }, validateTrigger: 'onBlur'
          }
        ]}
        className='marginbottom4'
        validateFirst
      >
        <Input
          maxLength={6}
          placeholder="请输入验证码"
          allowClear
          prefix={
            <div className='login-all-form-prefix'>
              <img src={require('../../../assets/images/login-code.png')} alt="" />
            </div>
          }
          suffix={
            time === -1 ? <div className='login-all-form-suffix-getCode' onClick={(e) => { e.stopPropagation(); time === -1 && getCode?.() }}>获取验证码</div> :
              <div className='login-all-form-suffix-getCodeValue'>{time}s后重发</div>
          }
          onPressEnter={login}
        />
      </Form.Item>
    </Form>

    <Typography.Link style={{ textAlign: 'right', width: 'fit-content', marginLeft: 'auto' }} onClick={passwordLogin}>密码登录</Typography.Link>

    <PriPolicy style={{ paddingTop: '10px', paddingBottom: '16px' }} />

    <Button loading={loading} onClick={login} htmlType='button' type='primary' block style={{ marginBottom: '30px' }}>登 录</Button>

    <OtherLogin />
  </>
}

export default LoginForm