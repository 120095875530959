import React, { useEffect, useState } from 'react'
import './index.less'
import { Modal } from 'antd'
import { getConfigOne, getInvitationCode } from '../../api/modules/common'

type HowGetCodeProps = {
    style?: React.CSSProperties
    className?: string
    onlyModal?: boolean    // 是否只是需要弹框
    open?: boolean         // 当仅需要弹框的时候控制弹框
    onCancel?: () => void  // 关闭弹框回调
}

const HowGetCode: React.FC<HowGetCodeProps> = (props) => {

    const { style, className, onlyModal = false, open, onCancel } = props

    // 如何获取分享码弹框控制
    const [show, setShow] = useState(onlyModal ? open : false)
    const [img, setImg] = useState('')

    // 获取邀请码的提示
    useEffect(() => {
        // getInvitationCode({ key: 'contactUs' }).then(res => {
        //     setImg(res.value)
        // }).catch(() => { })
        getConfigOne({ code: 'CONTACT_US', type: 'PLATFORM_CONFIG' }).then((res) => {
            setImg(res.config?.url ?? '')
        }).catch(() => { })
    }, [])

    return (
        <>
            { !onlyModal && <div className={className ? className + ' howgetcode-title' : 'howgetcode-title'} style={style} onClick={() => { img && setShow(true) }}>如何获取邀请码?</div>}
            <Modal
                open={show}
                wrapClassName={'howgetcode-modal'}
                onCancel={() => { setShow(false);onCancel?.() }}
                footer={false}
                closable={true}
                centered={true}
                width={'380px'}
                maskClosable={false}
                destroyOnClose={true}
                zIndex={9999}
            >
                <div className='title'>联系我们</div>
                <img src={img} alt="" />
            </Modal>
        </>
    )
}

export default HowGetCode