import React, { useEffect, useState } from 'react'
import { Button, Form, Input, message, Modal, Space } from 'antd'
import { editUserInfo } from '@/api/modules/person'

type SetAccountProps = {
    open: boolean
    onCancel?: () => void
    finish?: (data: any) => void
}

const SetAccount: React.FC<SetAccountProps> = (props) => {

    const { open, onCancel, finish } = props

    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    const save = async () => {
        const data = await form.validateFields().catch(() => { })
        if (!data) return
        setLoading(true)
        const res = await editUserInfo(data).catch(err => {
            form.setFields([
                {
                    name: 'accountName',
                    errors: [err?.message ?? '设置失败']
                }
            ])
        }).finally(() => {
            setLoading(false)
        })
        if (res != undefined) {
            finish?.(data)
            onCancel?.()
            message.destroy()
            message.success('账号设置成功')
        }
    }

    useEffect(() => {
        return () => {
            form.resetFields()
        }
    }, [open])

    return (
        <Modal
            open={open}
            wrapClassName='personal-center-modal'
            footer={
                <Space align="center" size={30} style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
                    <Button style={{ minWidth: '88px' }} onClick={onCancel}>取消</Button>
                    <Button style={{ minWidth: '88px' }} loading={loading} type="primary" onClick={save}>确认</Button>
                </Space>
            }
            closable={false}
            centered={true}
            width={550}
            maskClosable={false}
            destroyOnClose={true}
        >
            <p className='personal-center-modal-title'>设置账号</p>
            <div className='personal-center-modal-content'>
                <Form
                    colon={false}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        label={false}
                        name="accountName"
                        rules={[
                            { required: true, min: 6, max: 30, message: '请输入6-30位的账号', validateTrigger: 'onSubmit', whitespace: true },
                        ]}
                        validateFirst
                    >
                        <Input maxLength={30} showCount placeholder="请输入6-30位的账号" allowClear />
                    </Form.Item>
                </Form>

                <div className='setaccount-tips'>
                    <img src={require('../../../assets/images/hui-wenhao.png')} alt="" />
                    <div>
                        <p>1、账号为唯一标识，<span>设置后无法修改</span>，请谨慎操作；</p>
                        <p>2、仅支持大小写字母、数字、不支持输入中文、符号、特殊符号</p>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default SetAccount